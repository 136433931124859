import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { take } from 'rxjs/operators';

import { Contract } from '../model/contract';
import { Filter, FilterFields } from '../model/filter';
import { ContractService } from '../services/contract.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
})
export class FilterComponent implements AfterViewInit {
  @Output() applyFilter: EventEmitter<null> = new EventEmitter();
  @Output() searchText: EventEmitter<string> = new EventEmitter();
  @Output() resetFilter: EventEmitter<null> = new EventEmitter();
  @Input() filterFields: FilterFields;
  @Input() filter: Filter;

  searchTextString: string = '';
  contracts: Contract[] = [];
  contractTypes: string[] = ['PO', 'VO'];

  constructor(private contractService: ContractService) {}

  ngAfterViewInit() {
    this.searchTextString = this.filter.searchFilterText
    if (this.filterFields['contractNrs']) {
      this.getContractNumbers();
    }
  }

  getContractNumbers(): void {
    this.contractService
      .getContracts()
      .pipe(take(1))
      .subscribe((contracts) => {
        contracts.forEach((contract) => {
          this.contracts.push(contract);
        });
      });
  }

  onResetFilter() {
    this.searchTextString = '';
    this.resetFilter.emit();
  }

  sendFilter() {
    this.applyFilter.emit();
  }

  filterByText() {
    this.searchText.emit(this.searchTextString);
  }
}
