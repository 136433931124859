import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { Patch } from '../../shared/components/model/patch';
import { PatchService } from '../../shared/components/services/patch.service';
import {MatSort, Sort} from "@angular/material/sort";
import {Filter} from "../../shared/components/model/filter";

@Component({
  selector: 'app-patch',
  templateUrl: './patch.component.html',
  styleUrls: ['./patch.component.css'],
})
export class PatchComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  filter: Filter;

  dataSource = new MatTableDataSource<Patch>();
  displayedColumns: string[] = ['patchNr', 'description', 'isCompleted'];


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cdr.detectChanges();
  }

  constructor(private router: Router, private patchService: PatchService, private cdr: ChangeDetectorRef) {
    this.getPatches();
  }

  checkForPatches(): void {
    this.patchService.checkPatches().subscribe((res) => {
      this.getPatches();
    });
  }

  getPatches(): void {
    this.patchService.getPatches().subscribe((patches) => {
      this.dataSource.data = patches;
    });
  }

  goToPatch(patch: Patch): void {
    this.router.navigate(['/patch', patch.id]);
  }

  applyFilter(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const filterValue = inputElement.value.trim().toLowerCase();

    this.dataSource.filterPredicate = (data: Patch, filter: string) =>
      data.description.toLowerCase().includes(filter);
    this.dataSource.filter = filterValue;
  }
}
