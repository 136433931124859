import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AnnouncementComponent } from './modules/announcement/announcement.component';
import { AnnouncementEditComponent } from './modules/announcement/announcement-edit/announcement-edit.component';
import { AuditComponent } from './modules/audit/audit.component';
import { BagAddressComponent } from './modules/bag/bagAddress.component';
import { ContractComponent } from './modules/contract/contract.component';
import { ContractEditComponent } from './modules/contract/contract-edit/contract-edit.component';
import { CountryCodeComponent } from './modules/country/country.component';
import { DuoSyncComponent } from './modules/databaseUpdates/duo-sync/duo-sync.component';
import { UpdatedbComponent } from './modules/databaseUpdates/updatedb.component';
import { DuoSchoolEditComponent } from './modules/duo/duo-edit/duoSchool-edit.component';
import { DuoSchoolComponent } from './modules/duo/duoSchool.component';
import { EventComponent } from './modules/event/event.component';
import { LoggingComponent } from './modules/logging/logging.component';
import { NationalityCodeComponent } from './modules/nationality/nationality.component';
import { PatchComponent } from './modules/patch/patch.component';
import { PatchDetailComponent } from './modules/patch/patch-detail/patch-detail.component';
import { ReportComponent } from './modules/report/report.component';
import { SignupEditComponent } from './modules/signup-edit/signup-edit.component';
import { SignupStatsComponent } from './modules/signup-stats/signup-stats.component';
import { AuthGuard } from './shared/components/guards/AuthGuard';
import {CollaborationComponent} from "./modules/collaboration/collaboration.component";
import {CollaborationEditComponent} from "./modules/collaboration/collaboration-edit/collaboration-edit.component";

export const appRoutes: Routes = [
  {
    path: 'contract',
    component: ContractComponent,
    canActivate: [AuthGuard],
    data: { roles: ['contract'] },
  },
  {
    path: 'contract/:id',
    component: ContractEditComponent,
    canActivate: [AuthGuard],
    data: { roles: ['contract'] },
  },
  {
    path: 'signup/:id',
    component: SignupEditComponent,
    canActivate: [AuthGuard],
    data: { roles: ['support'] },
  },
  {
    path: 'duo',
    component: DuoSchoolComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'duo/:id',
    component: DuoSchoolEditComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'country',
    component: CountryCodeComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'nationality',
    component: NationalityCodeComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'bag',
    component: BagAddressComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'collaboration',
    component: CollaborationComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'collaboration/:id',
    component: CollaborationEditComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'event',
    component: EventComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'patch',
    component: PatchComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'patch/:id',
    component: PatchDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'updatedb',
    component: UpdatedbComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'updatedb/duosync',
    component: DuoSyncComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'stats',
    component: SignupStatsComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'report',
    component: ReportComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'logging',
    component: LoggingComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'audit',
    component: AuditComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'announcement',
    component: AnnouncementComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'announcement/:id',
    component: AnnouncementEditComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  { path: '**', redirectTo: 'contract' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
