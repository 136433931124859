<h2 mat-dialog-title>
  Samenwerkingsverband {{ collaborationId === undefined ? "toevoegen" : "bewerken" }}
</h2>
<mat-dialog-content>
  <form [formGroup]="collaborationForm">
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="Naam" formControlName="name" />
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    color="warn"
    mat-raised-button
    *ngIf="collaborationId !== undefined"
    (click)="delete()"
  >
    Verwijderen
  </button>
  <button mat-raised-button mat-dialog-close="true">Annuleren</button>
  <button color="primary"
          mat-raised-button
          (click)="save()"
          [disabled]="collaborationForm.invalid">
    Opslaan
  </button>
</mat-dialog-actions>
