import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';

import { Event as ExportEvent } from '../../shared/components/model/event';
import { Filter } from '../../shared/components/model/filter';
import { Logging, LoggingFilter } from '../../shared/components/model/logging';
import { DialogService } from '../../shared/components/services/dialog.service';
import { EventService } from '../../shared/components/services/event.service';
import { LoggingService } from '../../shared/components/services/logging.service';

@Component({
  selector: 'app-contract',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.css'],
})
export class LoggingComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  pageEvent: PageEvent;

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'contractNr',
    'signUpId',
    'sysCreatedAt',
    'lasType',
    'message',
    'actions',
  ];
  hoveredRow: number;

  lasTypes: string[] = ['Magister', 'Somtoday', 'Parnassys'];

  filter: LoggingFilter;

  constructor(
    private loggingService: LoggingService,
    private dialogService: DialogService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private eventService: EventService,
  ) {}

  ngOnInit(): void {
    if(sessionStorage.getItem('mgmtLoggingFilter')) {
      this.filter = JSON.parse(sessionStorage.getItem('mgmtLoggingFilter'))
    } else {
      this.resetFilter();
    }
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Aantal per pagina: ';
    this.dataSource.sort = this.sort;
    this.sort.sortChange.subscribe((sortInfo) => {
      this.onSort(sortInfo);
    });
    this.getLoggingItems();
  }
  onSort(sortInfo: Sort): void {
    this.filter.orderColumn = sortInfo.active;
    this.filter.orderDir = sortInfo.direction;

    this.getLoggingItems();
  }

  resetFilter(): void {
    sessionStorage.removeItem('mgmtLoggingFilter')
    this.filter = {
      totalItems: 0,
      currentPage: 0,
      limit: 0,
      pageSize: 20,
      totalPages: 0,
      orderColumn: 'contractNr',
      orderDir: 'desc',
      contractNr: null,
      signUpId: null,
      lasType: null,
    } as Filter;
    this.applyFilter();
  }

  applyFilter(): void {
    this.filter.currentPage = 0;
    this.getLoggingItems();
  }

  showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten', {
      duration: 5000,
    });
  }

  getLoggingItems(): void {
    sessionStorage.setItem('mgmtLoggingFilter', JSON.stringify(this.filter))

    this.loggingService
      .getLoggingItems(this.filter)
      .pipe(take(1))
      .subscribe((dto) => {
        this.filter.totalItems = dto.totalItems;
        this.filter.currentPage = dto.currentPage;
        this.dataSource.data = dto.loggingList;
      });
  }

  deleteLoggingItem(id: string): void {
    this.dialogService
      .confirmInputDialog(
        'U staat op het punt om een log item uit de uitvallijst te verwijderen. ' +
          'Weet u zeker dat u door wilt gaan?',
        'de log ID',
        id,
        true,
        'verwijdering',
      )
      .pipe(take(1))
      .subscribe((value) => {
        this.loggingService
          .deleteLoggingItem(id, value.audit)
          .pipe(
            tap({
              next: (_) => {
                this.showToast('Log item is verwijderd uit de uitvallijst.');
                this.getLoggingItems();
              },
              error: (_) => {
                this.showToast(
                  'Error: Log item is niet verwijderd uit de uitvallijst.',
                );
              },
            }),
          )
          .subscribe();
      });
  }

  onPageChange(e: any): PageEvent {
    this.filter.currentPage = e.pageIndex;
    this.filter.pageSize = e.pageSize;
    this.getLoggingItems();
    return e;
  }

  onMouseOver(rowIndex: number): void {
    this.hoveredRow = rowIndex;
  }

  onMouseOut(): void {
    this.hoveredRow = null;
  }

  openEditor(row): void {
    this.router.navigate(['/signup/' + row.signUpId]);
  }

  export(element: Logging): void {
    this.dialogService
      .confirmInputDialog(
        'U staat op het punt om een aanmelding te exporteren. ' +
          'Weet u zeker dat u door wilt gaan?',
        'het AanmeldNr',
        element.signUpId,
        false,
        'export',
      )
      .pipe(take(1))
      .subscribe((_) => {
        const event = {
          action: 'EXPORT',
          actionType: 'SIGNUP',
          contractNr: element.contractNr,
          identifier: element.signUpId,
          queue: 'export',
          msg: 'handmatig',
        } as ExportEvent;
        this.eventService
          .sendEvent(event)
          .pipe(take(1))
          .subscribe((_) => {
            this.showToast('Export event is verstuurd.');
          });
      });
  }
}
