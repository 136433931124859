<h2>Uitvallijst</h2>

<mat-card appearance="outlined">
  <mat-card-content>
    <div class="mdc-layout-grid mdc-layout-grid--align-left">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="ContractNr"
              [(ngModel)]="filter.contractNr"
            />
          </mat-form-field>
        </div>

        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="AanmeldNr"
              [(ngModel)]="filter.signUpId"
            />
          </mat-form-field>
        </div>

        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2">
          <mat-form-field class="full-width">
            <mat-label>LasType</mat-label>
            <mat-select [(ngModel)]="filter.lasType">
              <mat-option *ngFor="let type of lasTypes" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
          <button mat-raised-button color="primary" (click)="getLoggingItems()">
            <mat-icon>filter_alt</mat-icon> Pas filter toe
          </button>
          <button mat-raised-button (click)="resetFilter()" class="mr-5">
            <mat-icon>search_off</mat-icon> Reset filter
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<br />
<mat-card appearance="outlined">
  <mat-card-content>
    <div class="mat-elevation-z0">
      <table
        mat-table
        matSort
        matSortActive="sysCreatedAt"
        matSortDirection="asc"
        matSortDisableClear
        aria-label="Selecteer"
        [dataSource]="dataSource"
      >
        <ng-container matColumnDef="contractNr">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract</th>
          <td mat-cell *matCellDef="let element">{{ element.contractNr }}</td>
        </ng-container>

        <ng-container matColumnDef="signUpId">
          <th mat-header-cell *matHeaderCellDef>AanmeldNr</th>
          <td mat-cell *matCellDef="let element">{{ element.signUpId }}</td>
        </ng-container>

        <ng-container matColumnDef="sysCreatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sinds</th>
          <td mat-cell *matCellDef="let element"> {{ element.sysCreatedAt | date: "dd-MM-yyyy hh:mm:ss" }}</td>
        </ng-container>

        <ng-container matColumnDef="lasType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>LasType</th>
          <td mat-cell *matCellDef="let element">{{ element.lasType }}</td>
        </ng-container>

        <ng-container matColumnDef="exportState">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ExportState</th>
          <td mat-cell *matCellDef="let element">{{ element.exportState }}</td>
        </ng-container>

        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef>Error</th>
          <td mat-cell *matCellDef="let element">
            {{ element.message }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acties</th>
          <td mat-cell *matCellDef="let element; let rowIndex = index">
            <div class="button-container">
              <button
                mat-icon-button
                color="primary"
                [disabled]="rowIndex !== hoveredRow"
                (click)="export(element)"
                aria-label="exporteren"
                matTooltip="Exporteer"
              >
                <mat-icon>arrow_forward</mat-icon>
              </button>
              <button
                color="warn"
                (click)="deleteLoggingItem(element.id)"
                [disabled]="rowIndex !== hoveredRow"
                mat-icon-button
                aria-label="verwijderen"
                matTooltip="Verwijder"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns; let rowIndex = index"
          (mouseover)="onMouseOver(rowIndex)"
          (mouseout)="onMouseOut()"
          (dblclick)="openEditor(row)"
          class="detail-row"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            Geen logging items gevonden
          </td>
        </tr>
      </table>
      <mat-paginator
        [length]="filter.totalItems"
        [pageSize]="filter.pageSize"
        [pageIndex]="filter.currentPage"
        [pageSizeOptions]="[10, 20, 50, 100]"
        (page)="pageEvent = onPageChange($event)"
        [showFirstLastButtons]="true"
        aria-label="Selecteer"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
