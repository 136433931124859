import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { DuoSchool } from '../../shared/components/model/duoSchool';
import { DuoSchoolService } from '../../shared/components/services/duoSchool.service';
import { CollaborationEditComponent } from './collaboration-edit/collaboration-edit.component';
import {CollaborationService} from "../../shared/components/services/collaboration.service";
import {Collaboration} from "../../shared/components/model/collaboration";

@Component({
  selector: 'app-collaboration',
  templateUrl: './collaboration.component.html',
  styleUrls: ['./collaboration.component.css'],
})
export class CollaborationComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<Collaboration>();
  displayedColumns: string[] = [
    'name',
  ];

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private router: Router,
    private collaborationService: CollaborationService,
    private readonly dialog: MatDialog,
  ) {
    this.getCollaborations();
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getCollaborations(): void {
    this.collaborationService
      .getCollaborations()
      .pipe(take(1))
      .subscribe((collaborations) => {
        this.dataSource.data = collaborations;
      });
  }

  goToCollaboration(collaboration: Collaboration): void {

    const dialogConfig = {
      minWidth: '900px',
      maxWidth: '65vw',
      minHeight: '15vh',
      maxHeight: '50vh',
      data: collaboration?.id,
    };

    const dialogRef = this.dialog.open(CollaborationEditComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.getCollaborations();
      });
  }
}
