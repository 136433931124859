<h2>Audit Logs</h2>

<mat-card appearance="outlined">
  <mat-card-content>
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2">
          <mat-form-field class="full-width">
            <mat-label>Gebruiker</mat-label>
            <mat-select [(ngModel)]="auditDTO.accounts" [multiple]="true">
              <mat-option *ngFor="let account of accounts" [value]="account">
                {{ account }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
          <mat-form-field class="full-width">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="auditDTO.objecttypes" [multiple]="true">
              <mat-option *ngFor="let type of objecttypes" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
          <mat-form-field class="full-width">
            <mat-label>Actie</mat-label>
            <mat-select [(ngModel)]="auditDTO.actions" [multiple]="true">
              <mat-option *ngFor="let action of actions" [value]="action">
                {{ action }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2">
          <mat-form-field class="full-width">
            <mat-label>Audit logs vanaf</mat-label>
            <input matInput type="date" [(ngModel)]="auditDTO.auditLogsFrom" />
          </mat-form-field>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2">
          <mat-form-field class="full-width">
            <mat-label>Audit logs tot en met</mat-label>
            <input matInput type="date" [(ngModel)]="auditDTO.auditLogsUntil" />
          </mat-form-field>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
          <mat-form-field class="full-width">
            <mat-label><mat-icon>search</mat-icon> Zoeken op ID </mat-label>
            <input
              matInput
              type="input"
              name="search"
              (keydown.enter)="getAuditsByFilter()"
              [(ngModel)]="auditDTO.objectId"
              placeholder="zoek op ID"
            />
            <button *ngIf="auditDTO.objectId"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="clearSearch()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
          <div class="full-width" >
            <button
              mat-raised-button
              color="primary"
              (click)="getAuditsByFilter()"
            >
              <mat-icon>filter_alt</mat-icon> Pas filter toe
            </button>
            <button mat-raised-button (click)="resetFilter()">
              <mat-icon>search_off</mat-icon> Reset filter
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br />
<mat-card appearance="outlined">
  <mat-card-content>
    <table
      mat-table
      matSort
      [dataSource]="dataSource"
      aria-label="audits"
    >
      <ng-container matColumnDef="accountName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gebruiker</th>
        <td mat-cell *matCellDef="let row">{{ row.accountName }}</td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bericht</th>
        <td mat-cell *matCellDef="let row">{{ row.message }}</td>
      </ng-container>

      <ng-container matColumnDef="objectType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let row">{{ row.objectType }}</td>
      </ng-container>

      <ng-container matColumnDef="objectId">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let row">
          {{
            row.objectIds !== undefined &&
            row.objectIds !== null &&
            row.objectIds.length > 0
              ? ""
              : row.objectId
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actie</th>
        <td mat-cell *matCellDef="let row">{{ row.action }}</td>
      </ng-container>

      <ng-container matColumnDef="sysCreatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum/Tijd</th>
        <td mat-cell *matCellDef="let row">
          {{ row.sysCreatedAt | date: "dd-MM-yyyy hh:mm:ss" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="non-clickable-row"
      ></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          Geen auditlogs gevonden
        </td>
      </tr>
    </table>
    <mat-paginator
      [length]="auditDTO.totalItems"
      [pageSize]="auditDTO.pageSize"
      [pageIndex]="auditDTO.currentPage"
      [pageSizeOptions]="[10, 20, 50, 100]"
      (page)="pageEvent = onPageChange($event)"
      [showFirstLastButtons]="true"
      aria-label="Selecteer"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
