import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localeNl from '@angular/common/locales/nl';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import {
  KeycloakAngularModule,
  KeycloakEventType,
  KeycloakService,
} from 'keycloak-angular';
import { noop } from 'rxjs';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AnnouncementComponent } from './modules/announcement/announcement.component';
import { AnnouncementEditComponent } from './modules/announcement/announcement-edit/announcement-edit.component';
import { AuditComponent } from './modules/audit/audit.component';
import { BagAddressComponent } from './modules/bag/bagAddress.component';
import { ContractComponent } from './modules/contract/contract.component';
import { AddressComponent } from './modules/contract/contract-edit/address/address.component';
import { ConnectLasInfoDialogComponent } from './modules/contract/contract-edit/connect-las-info-dialog/connect-las-info-dialog.component';
import { ContactComponent } from './modules/contract/contract-edit/contact/contact.component';
import { ContractEditComponent } from './modules/contract/contract-edit/contract-edit.component';
import { CountryCodeComponent } from './modules/country/country.component';
import { DuoSyncComponent } from './modules/databaseUpdates/duo-sync/duo-sync.component';
import { DuoSyncTableComponent } from './modules/databaseUpdates/duo-sync/duo-sync-table/duo-sync-table.component';
import { UpdatedbComponent } from './modules/databaseUpdates/updatedb.component';
import { DuoSchoolEditComponent } from './modules/duo/duo-edit/duoSchool-edit.component';
import { DuoSchoolComponent } from './modules/duo/duoSchool.component';
import { EventComponent } from './modules/event/event.component';
import { LoggingComponent } from './modules/logging/logging.component';
import { NationalityCodeComponent } from './modules/nationality/nationality.component';
import { PatchComponent } from './modules/patch/patch.component';
import { PatchDetailComponent } from './modules/patch/patch-detail/patch-detail.component';
import { ReportComponent } from './modules/report/report.component';
import { SignupEditComponent } from './modules/signup-edit/signup-edit.component';
import { SignupStatsComponent } from './modules/signup-stats/signup-stats.component';
import { AppFooterComponent } from './shared/components/app-footer';
import { AppHeaderComponent } from './shared/components/app-header';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmWithInputDialogComponent } from './shared/components/confirm-with-input-dialog/confirm-with-input-dialog.component';
import { HasRoleDirective } from './shared/components/directives/has-role.directive';
import { IsAuthenticatedDirective } from './shared/components/directives/is-authenticated.directive';
import { FilterComponent } from './shared/components/filter/filter.component';
import { HeaderMenuItemComponent } from './shared/components/header-menu-item';
import { JaNeePipe } from './shared/components/pipes/ja-nee.pipe';
import { AnnouncementService } from './shared/components/services/announcement.service';
import { AuditService } from './shared/components/services/audit.service';
import { BagDataService } from './shared/components/services/bagData.service';
import { ContractService } from './shared/components/services/contract.service';
import { DialogService } from './shared/components/services/dialog.service';
import { DuoSchoolService } from './shared/components/services/duoSchool.service';
import { EventService } from './shared/components/services/event.service';
import { LoggingService } from './shared/components/services/logging.service';
import { PatchService } from './shared/components/services/patch.service';
import { ReportService } from './shared/components/services/report.service';
import { SignupService } from './shared/components/services/signup.service';
import { SignupStatsService } from './shared/components/services/signup-stats.service';
import { UpdatedbService } from './shared/components/services/updatedb.service';
import {CollaborationComponent} from "./modules/collaboration/collaboration.component";
import {CollaborationEditComponent} from "./modules/collaboration/collaboration-edit/collaboration-edit.component";
import {CollaborationService} from "./shared/components/services/collaboration.service";

registerLocaleData(localeNl, 'nl-NL', localeNlExtra);
export const MY_FORMAT = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MMM',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

function initializeKeycloak(keycloak: KeycloakService): any {
  keycloak.keycloakEvents$.subscribe({
    next: (e) => {
      if (e.type == KeycloakEventType.OnTokenExpired) {
        keycloak.updateToken(20).then(noop);
      }
    },
  });

  return () =>
    keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
    });
}

@NgModule({ declarations: [
        AppComponent,
        AppHeaderComponent,
        AppFooterComponent,
        ContractComponent,
        PatchComponent,
        PatchDetailComponent,
        DuoSchoolComponent,
        CollaborationComponent,
        ContractEditComponent,
        ConfirmDialogComponent,
        ConfirmWithInputDialogComponent,
        ConnectLasInfoDialogComponent,
        DuoSchoolEditComponent,
        CollaborationEditComponent,
        CountryCodeComponent,
        NationalityCodeComponent,
        BagAddressComponent,
        EventComponent,
        UpdatedbComponent,
        DuoSyncComponent,
        DuoSyncTableComponent,
        HeaderMenuItemComponent,
        HasRoleDirective,
        IsAuthenticatedDirective,
        ContactComponent,
        AddressComponent,
        ReportComponent,
        SignupStatsComponent,
        LoggingComponent,
        FilterComponent,
        SignupEditComponent,
        AnnouncementComponent,
        AnnouncementEditComponent,
        AuditComponent,
    ],
    bootstrap: [AppComponent],
    exports: [AnnouncementComponent], imports: [BrowserModule,
        KeycloakAngularModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        BrowserAnimationsModule,
        JaNeePipe,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatDialogModule,
        MatCheckboxModule,
        MatSelectModule,
        MatSnackBarModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatSortModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatTableModule,
        MatTableModule,
        MatPaginatorModule,
        NgJsonEditorModule,
        MatChipsModule], providers: [
        ContractService,
        PatchService,
        DuoSchoolService,
        CollaborationService,
        BagDataService,
        UpdatedbService,
        DialogService,
        EventService,
        ReportService,
        SignupStatsService,
        LoggingService,
        SignupService,
        AnnouncementService,
        AuditService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        {
            provide: MatDialogRef,
            useValue: {},
        },
        { provide: LOCALE_ID, useValue: 'nl-NL' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
