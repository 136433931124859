import {Clipboard} from '@angular/cdk/clipboard';
import {formatDate} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, Validators,} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import saveAs from 'file-saver';
import {take} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {Contract, SelectOption,} from '../../../shared/components/model/contract';
import {ContractService} from '../../../shared/components/services/contract.service';
import {DialogService} from '../../../shared/components/services/dialog.service';
import {ModuleReferralType} from "../../../shared/components/model/moduleReferralType";
import {CollaborationService} from "../../../shared/components/services/collaboration.service";
import {Collaboration} from "../../../shared/components/model/collaboration";

const NO_OPTION: SelectOption = {
  text: '',
  value: 'Kies een pool of type hier een nieuwe naam in',
};

@Component({
  selector: 'app-contract-edit',
  templateUrl: './contract-edit.component.html',
  styleUrls: ['./contract-edit.component.css'],
})
export class ContractEditComponent implements OnInit {
  contract: Contract = {
    name: '',
    contacts: [],
    addresses: [],
    poolList: [],
  } as Contract;
  llqApUrl: string = environment.llqApUrl;
  llqBpUrl: string = environment.llqBpUrl;

  llqApPort: string = environment.llqApPort;
  llqBpPort: string = environment.llqBpPort;

  isCopied: boolean = false;

  contractForm: FormGroup = this.fb.group({
    name: [null, Validators.required],
    id: [{ value: null, disabled: true }],
    contractNr: [{ value: null, disabled: true }],
    type: [{ value: 'VO', disabled: false }],
    dvo: [null, Validators.required],
    contractStartDate: [null, Validators.required],
    remarks: [null],
    hostName: [null],
    dbStatusModifiedAt: [{ value: null, disabled: true }],
    kcStatusModifiedAt: [{ value: null, disabled: true }],
    supportStatusModifiedAt: [{ value: null, disabled: true }],
    forecast: [null],
    isActive: [null],
    isTest: [null],
    isOnboarding: [null],
    referral: false,
    referralCollaboration: false,
    referralModuleActive: [null],
    moduleReferralType: [null],
    collaboration: [null],
    numberOfTiles: [null],
    poolList: [null],
  });

  contractId: string;

  collaborations: Collaboration[];

  contractTypes = ['PO', 'VO'];

  selectedOption: SelectOption = { value: '', text: '' };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contractService: ContractService,
    private collaborationService: CollaborationService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private readonly dialogService: DialogService,
    private readonly clipboard: Clipboard,
  ) {}

  ngOnInit(): void {
    this.contractId = this.route.snapshot.paramMap.get('id');

    if (this.contractId !== 'new') {
      this.contractForm.controls['type'].disable();
      this.contractService
        .getContract(this.contractId)
        .pipe(take(1))
        .subscribe((contract) => {
          this.contract = contract;

          let referralType = this.contract.moduleReferralType;
          this.contractForm.get('referral').setValue(referralType === ModuleReferralType.REFERRAL)
          this.contractForm.get('referralCollaboration').setValue(referralType === ModuleReferralType.REFERRAL_COLLABORATION)

          if(referralType === ModuleReferralType.REFERRAL_COLLABORATION) {
            // onInit set the collaboration selection if the contract has the data
            if(this.contract.moduleCollaborationId)
              this.onReferralCollaborationSelection(true)
          }

          this.contractForm.patchValue(this.contract);

          this.contractForm
            .get('dbStatusModifiedAt')
            .setValue(
              formatDate(
                this.contractForm.get('dbStatusModifiedAt').value,
                'medium',
                'nl-NL',
              ),
            );
          this.contractForm
            .get('kcStatusModifiedAt')
            .setValue(
              formatDate(
                this.contractForm.get('kcStatusModifiedAt').value,
                'medium',
                'nl-NL',
              ),
            );

          this.contractForm
            .get('supportStatusModifiedAt')
            .setValue(
              formatDate(
                this.contractForm.get('supportStatusModifiedAt').value,
                'medium',
                'nl-Nl',
              ),
            );

          const onboarding = this.contract.onboarding;
          if (onboarding !== null) {
            this.contractForm
              .get('numberOfTiles')
              .setValue(onboarding.numberOfTiles);
          }

          const poolList = this.contract.poolList;
          if (poolList != null) {
            this.contractForm.get('poolList').setValue(poolList);
          } else {
            this.contract.poolList = [];
          }

          if (this.contract.contacts == null) {
            this.contract.contacts = [];
          }
          if (this.contract.addresses == null) {
            this.contract.addresses = [];
          }
        });
    }
  }

  copy(contract: Contract): void {
    let locationProtocol = 'https:';
    if (location.protocol === 'http:') {
      locationProtocol = 'http:';
    }

    let onboardingLink =
      locationProtocol +
      '//' +
      contract.contractNr +
      this.llqBpUrl +
      this.llqBpPort +
      '/onboarding/' +
      contract.id +
      '/' +
      contract.onboardingKey;
    this.isCopied = true;

    this.clipboard.copy(onboardingLink);
  }

  back(): void {
    this.router.navigate(['/contract']);
  }

  save(): void {
    this.getFormValidationErrors();

    const referral = this.contractForm.get('referral')?.value;
    const referralCollaboration = this.contractForm.get('referralCollaboration')?.value;

    this.contractForm.get('referralModuleActive').setValue(!!referral || !!referralCollaboration);

    if (referral) {
      this.contractForm.get('moduleReferralType').setValue(ModuleReferralType.REFERRAL);
    } else if (referralCollaboration) {
      this.contractForm.get('moduleReferralType').setValue(ModuleReferralType.REFERRAL_COLLABORATION);
    } else {
      this.contractForm.get('collaboration').setValue(null);
      this.contractForm.get('moduleReferralType').setValue(null);
    }

    if (this.contractForm.invalid) return;

    this.contractForm.get('poolList').setValue(this.contract.poolList);
    if (this.contractId !== 'new') {
      this.contractService
        .saveContract(this.contractForm.getRawValue())
        .subscribe((data) => {
          this.showToast(data.name + ' is toegevoegd.');
          this.router.navigate(['contract']);
        });
    } else {
      this.contractService
        .addContract(this.contractForm.getRawValue())
        .subscribe((data) => {
          this.showToast(data.name + ' is geupdate.');
          this.router.navigate(['contract']);
        });
    }
  }
  getFormValidationErrors(): void {
    Object.keys(this.contractForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.contractForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {});
      }
    });
  }
  delete(): void {
    if (this.contractId === 'new') return;

    this.dialogService
      .confirm(
        'U staat op het punt om dit contract te verwijderen, Weet u zeker dat u door wilt gaan?',
      )
      .pipe(take(1))
      .subscribe((_) => {
        this.contractService
          .deleteContract(this.contract)
          .pipe(take(1))
          .subscribe((data) => {
            this.showToast(this.contract.name + ' is verwijderd.');
            this.router.navigate(['contract']);
          });
      });
  }

  /*
   fires the event provisioning-db
   */
  checkDatabase(): void {
    if (this.contractId !== 'new') {
      this.contractService.checkDb(this.contract).subscribe((data) => {
        this.showToast('Database gecheckt');
        this.router.navigate(['contract']);
      });
    }
  }

  /*
   fires the event provisioning-db
   */
  checkRoles(): void {
    if (this.contractId !== 'new') {
      this.contractService.checkRoles(this.contract).subscribe((data) => {
        this.showToast('Rollen gecheckt');
      });
    }
  }
  /*
    fires the event provisioning-kc
   */
  checkKeycloakRealms(): void {
    if (this.contractId !== 'new') {
      this.contractService.checkKc(this.contract).subscribe((data) => {
        this.showToast('Keycloak realms gecheckt');
        this.router.navigate(['contract']);
      });
    }
  }

  getKeycloakJson(): void {
    this.contractService
      .getKcZip(this.contract)
      .subscribe((response) =>
        saveAs(response, 'keycloak_' + this.contract.contractNr + '.zip'),
      );
  }
  /*
    fires the event provisioning-support
   */
  checkSupport(): void {
    if (this.contractId !== 'new') {
      this.contractService.checkSupport(this.contract).subscribe((data) => {
        this.showToast('Support organization gecheckt');
        this.router.navigate(['contract']);
      });
    }
  }
  /*
   fires the event to remove
  */
  removeSupport(): void {
    if (this.contractId !== 'new') {
      this.contractService.removeSupport(this.contract).subscribe((data) => {
        this.showToast('Support organization verwijderd');
        this.router.navigate(['contract']);
      });
    }
  }

  public showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten');
  }

  goTo(event: any, type: string): void {
    event.stopPropagation();
    window.open(
      location.protocol +
        '//' +
        this.contract.contractNr +
        (type === 'ap' ? this.llqApUrl : this.llqBpUrl) +
        (type === 'ap' ? this.llqApPort : this.llqBpPort),
      '_blank',
    );
  }

  public changeIsOnboarding(): void {
    const changedIsOnboardingValue = this.contractForm
      .get('isOnboarding')
      .getRawValue();
    // Changed checkbox from true to false.
    if (!changedIsOnboardingValue) {
      this.dialogService
        .open(
          'U staat op het punt om dit contract van onboarding state af te halen, Weet u zeker dat u door wilt gaan?',
        )
        .pipe(take(1))
        .subscribe((_) => {
          if (!_) {
            // Onboarding stage moet aan blijven staan, zet value terug op true.
            this.contractForm.get('isOnboarding').setValue(true);
          }
        });
    }
  }

  linkSchoolToLasInfo(): void {
    this.dialogService
      .connectLasInfoDialog(this.contractId, this.contract.name)
      .pipe(take(1))
      .subscribe();
  }

  readyForProduction(): void {
    this.dialogService
      .confirmInputDialog(
        'U staat op het punt informatie over het contract uit de database te verwijderen. ' +
          'Weet u zeker dat u door wilt gaan?',
        'het contractnummer',
        this.contract.contractNr.toString(),
        false,
        '',
      )
      .pipe(take(1))
      .subscribe((_) => {
        this.contractService
          .updateContractForProduction(this.contract.contractNr.toString())
          .pipe(take(1))
          .subscribe((httpStatus) => {
            if (httpStatus === 204) {
              this.showToast(
                this.contract.name +
                  ' was al productie gereed. De database is niet gewijzigd.',
              );
            } else {
              this.showToast(this.contract.name + ' is nu productie gereed.');
              this.contractForm.get('isOnboarding').setValue(false);
              this.contract.inProduction = true;
            }
          });
      });
  }

  get showAddOption(): boolean {
    return (
      !this.contract.poolList.includes(this.selectedOption) &&
      this.showClearOption
    );
  }

  get showClearOption(): boolean {
    return this.selectedOption.text !== NO_OPTION.text;
  }

  addOption(): void {
    this.contract.poolList.push({ ...this.selectedOption });
    this.clearOption();
  }

  clearOption(): void {
    this.selectedOption = { ...NO_OPTION };
  }

  changeOption(): void {
    this.selectedOption.value = this.selectedOption.text;
  }

  deleteOption(option: SelectOption): void {
    this.contract.poolList = this.contract.poolList.filter((o) => o !== option);
    this.clearOption();
  }

  selectOption(option: SelectOption): void {
    // Check clicked option is already selected, then remove selection.
    if (this.selectedOption !== option) {
      this.selectedOption = option;
    } else {
      this.clearOption();
    }
  }

  onReferralCollaborationSelection(onInit: boolean = false) {
    this.collaborationService
      .getCollaborations()
      .pipe(take(1))
      .subscribe((collaborations) => {
        this.collaborations = collaborations;
        // When opening edit menu run as onInit
        if(onInit) {
          this.contractForm.get('collaboration').setValue(
            collaborations.filter(collaboration => {
              return collaboration.id === this.contract?.moduleCollaborationId;
            })[0]
          )
        }
      });
  }

  isReferralCollaborationActive() {
    return this.contractForm.get("referralCollaboration").getRawValue()
  }

  changeReferral(selectedCheckbox: string) {
    const otherCheckbox = selectedCheckbox === 'referral' ? 'referralCollaboration' : 'referral';
    const isSelected = this.contractForm.get(selectedCheckbox).getRawValue();

    if(selectedCheckbox === 'referralCollaboration') {
      if(isSelected) this.onReferralCollaborationSelection();
      this.contractForm.get('collaboration').setValue(null);
    }

    if (isSelected) {
      // Select current checkbox and deselect the other one
      this.contractForm.get(otherCheckbox).setValue(false);
    } else {
      // If the selected checkbox is already deselected, leave both deselected
      this.contractForm.get(selectedCheckbox).setValue(false);
      this.contractForm.get(otherCheckbox).setValue(false);
    }
  }

  isFormInvalid() {
    if(this.contractForm.get('referralCollaboration').getRawValue())
      if(this.contractForm.get('collaboration').getRawValue() === null) return true
    return false;
  }
}
