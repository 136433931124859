import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BagAddress } from '../model/adresses';
import { DuoSchool } from '../model/duoSchool';
import { Postalcodelookup } from '../model/postalcodelookup';
import {DuoSync} from "../../../modules/databaseUpdates/duo-sync/duo-sync.component";
import {Collaboration} from "../model/collaboration";

@Injectable()
export class CollaborationService {
  urlCollaboration = '/api/collaboration/';

  constructor(private http: HttpClient) {}

  getCollaborations(): Observable<Collaboration[]> {
    return this.http.get<Collaboration[]>(this.urlCollaboration);
  }

  getCollaboration(collaborationId: string): Observable<Collaboration> {
    return this.http.get<Collaboration>(this.urlCollaboration + collaborationId);
  }

  saveCollaboration(collaboration: Collaboration): Observable<Collaboration> {
    return this.http.put<Collaboration>(
      this.urlCollaboration + collaboration.id,
      collaboration
    );
  }

  addCollaboration(collaboration: Collaboration): Observable<Collaboration> {
    return this.http.post<Collaboration>(this.urlCollaboration, collaboration);
  }

  deleteCollaboration(collaboration: Collaboration): Observable<Collaboration> {
    return this.http.delete<Collaboration>(this.urlCollaboration + collaboration.id);
  }
}
