import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { Audit, AuditDTO } from '../../shared/components/model/audit';
import { AuditService } from '../../shared/components/services/audit.service';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['audit.component.css'],
  providers: [MatSort],
})
export class AuditComponent implements OnInit, AfterViewInit {
  @ViewChild('dateTmpl', { static: true }) dateTmplRef: TemplateRef<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  objecttypes: string[] = [];
  actions: string[] = [];
  accounts: string[] = [];

  auditDTO: AuditDTO = {
    totalItems: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: 20,
    orderColumn: 'sysCreatedAt',
    orderDir: 'desc',
    objecttypes: [],
    actions: [],
    accounts: [],
  } as AuditDTO;

  dataSource = new MatTableDataSource();
  displayedColumns = [
    'accountName',
    'message',
    'objectType',
    'objectId',
    'action',
    'sysCreatedAt',
  ];
  pageEvent: PageEvent;

  constructor(
    private readonly router: Router,
    private readonly auditService: AuditService,
  ) {}

  ngOnInit(): void {
    this.auditService.getObjecttypes().subscribe((data: string[]) => {
      this.objecttypes = data;
    });

    this.auditService.getActions().subscribe((data: string[]) => {
      this.actions = data;
    });

    this.auditService.getAccounts().subscribe((data: string[]) => {
      this.accounts = data;
    });

    if(sessionStorage.getItem('mgmtAuditFilter')) {
      this.auditDTO = JSON.parse(sessionStorage.getItem('mgmtAuditFilter'))
    }

    this.getAudits();
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Aantal per pagina: ';
    this.dataSource.sort = this.sort;
    this.sort.sortChange.subscribe((sortInfo) => {
      this.onSort(sortInfo);
    });
  }

  onSort(sortInfo): void {
    this.auditDTO.orderColumn = sortInfo.active;
    this.auditDTO.orderDir = sortInfo.direction;

    this.getAudits();
  }

  resetFilter(): void {
    sessionStorage.removeItem('mgmtAuditFilter')
    this.auditDTO.objectId = '';
    this.auditDTO.objecttypes = [];
    this.auditDTO.actions = [];
    this.auditDTO.accounts = [];
    this.auditDTO.auditLogsFrom = null;
    this.auditDTO.auditLogsUntil = null;
    this.getAudits();
  }

  resetPaging(): void {
    this.auditDTO.currentPage = 0;
    this.auditDTO.orderColumn = 'sysCreatedAt';
    this.auditDTO.orderDir = 'desc';
  }

  getAuditsByFilter(): void {
    this.resetPaging();
    this.getAudits();
  }

  getAudits(): void {
    sessionStorage.setItem('mgmtAuditFilter', JSON.stringify(this.auditDTO))

    this.auditService.getAudits(this.auditDTO).subscribe((dto) => {
      this.auditDTO.totalItems = dto.totalItems;
      this.auditDTO.currentPage = dto.currentPage;
      this.setDataSource(dto.audits);
    });
  }

  setDataSource(audits: Audit[]) {
    this.paginator._intl.itemsPerPageLabel = 'Aantal per pagina: ';
    this.dataSource.data = audits;
  }

  onPageChange($event: any): PageEvent {
    this.auditDTO.pageSize = $event.pageSize;
    this.auditDTO.currentPage = $event.pageIndex;
    this.getAudits();
    return $event;
  }

  clearSearch(): void {
    this.auditDTO.objectId = '';
    this.getAudits();
  }
}
