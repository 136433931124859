import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { BagAddress } from '../../../shared/components/model/adresses';
import { DuoSchool } from '../../../shared/components/model/duoSchool';
import { Postalcodelookup } from '../../../shared/components/model/postalcodelookup';
import { DuoSchoolService } from '../../../shared/components/services/duoSchool.service';
import {Collaboration} from "../../../shared/components/model/collaboration";
import {CollaborationService} from "../../../shared/components/services/collaboration.service";

@Component({
  selector: 'app-collaboration-edit',
  templateUrl: './collaboration-edit.component.html',
  styleUrls: ['./collaboration-edit.component.css'],
})
export class CollaborationEditComponent implements OnInit {
  collaboration: Collaboration = {
    name: '',
  } as Collaboration;

  collaborationForm: FormGroup = this.fb.group({
    id: [{ value: null, disabled: true }],
    name: [null, Validators.required],
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private collaborationService: CollaborationService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    public readonly dialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA) public readonly collaborationId: string,
  ) {}

  ngOnInit(): void {
    if (this.collaborationId !== undefined) {
      this.collaborationService
        .getCollaboration(this.collaborationId)
        .pipe(take(1))
        .subscribe((collaboration) => {
          this.collaboration = collaboration;
          this.collaborationForm.patchValue(collaboration);
        });
    }
  }

  save(): void {
    if (this.collaborationForm.invalid) return;

    if (this.collaborationId !== undefined) {
      this.collaborationService
        .saveCollaboration(this.collaborationForm.getRawValue())
        .pipe(take(1))
        .subscribe((data) => {
          this.showToast(data + ' is aangepast.');
          this.dialogRef.close();
        });
    } else {
      this.collaborationService
        .addCollaboration(this.collaborationForm.getRawValue())
        .pipe(take(1))
        .subscribe((collaboration) => {
          this.showToast(collaboration.name + ' is toegevoegd.');
          this.dialogRef.close();
        });
    }
  }

  delete(): void {
    if (this.collaborationId !== undefined) {
      this.collaborationService
        .deleteCollaboration(this.collaboration)
        .pipe(take(1))
        .subscribe(
          (_) => {
            this.showToast('Samenwerkingsverband succesvol verwijderd.')
            this.dialogRef.close();
          },
          error => {
            if(error.status === 400) {
              this.showToast('Verwijderen niet mogelijk.' +
                ' Er zijn nog contracten gelinkt aan het samenwerkingsverband');
            } else {
              this.showToast('Verwijderen niet mogelijk.')
            }
            this.dialogRef.close();
          });
    }

  }

  public showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten');
  }
}
