import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { noop } from 'rxjs';

import { MenuItem } from '../model/menu-item';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css'],
})
export class AppHeaderComponent implements OnInit {
  menuItems: Array<MenuItem> = [
    {
      label: 'Contract',
      icon: 'app_registration',
      roles: 'contract',
      path: 'contract',
    },
    {
      label: 'DUO',
      icon: 'school',
      roles: 'getthere',
      path: 'duo',
    },
    {
      label: 'Stamtabellen',
      icon: 'assessment',
      roles: 'getthere',
      items: [
        {
          label: 'Landen',
          icon: 'flag',
          roles: 'getthere',
          path: 'country',
        },
        {
          label: 'Nationaliteiten',
          icon: 'flag',
          roles: 'getthere',
          path: 'nationality',
        },
        {
          label: 'Addressen',
          icon: 'home',
          roles: 'getthere',
          path: 'bag',
        },
        {
          label: 'Samenwerkingsverbanden',
          icon: 'handshake',
          roles: 'getthere',
          path: 'collaboration',
        },
        {
          label: 'Update DB',
          icon: 'sync',
          roles: 'getthere',
          path: 'updatedb',
        },
      ],
    },
    {
      label: 'Statistiek',
      icon: 'equalizer',
      roles: 'getthere',
      path: 'stats',
    },
    {
      label: 'Rapportage',
      icon: 'assignment',
      roles: 'getthere',
      path: 'report',
    },
    {
      label: 'Patchen',
      icon: 'plumbing',
      roles: 'getthere',
      path: 'patch',
    },
    {
      label: 'Events',
      icon: 'event',
      roles: 'getthere',
      path: 'event',
    },
    {
      label: 'Uitvallijst',
      icon: 'info',
      roles: 'support',
      path: 'logging',
    },
    {
      label: 'Meldingen',
      icon: 'priority_high',
      roles: 'getthere',
      path: 'announcement',
    },
    {
      label: 'Audit Log',
      icon: 'list',
      roles: 'getthere',
      path: 'audit',
    },
  ];
  isLoggedIn = false;
  profile: KeycloakProfile;

  constructor(private readonly keycloakService: KeycloakService) {}

  ngOnInit(): void {
    this.loadAccountState().then(noop);
  }

  async loadAccountState(): Promise<void> {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();

    if (this.isLoggedIn) {
      this.profile = await this.keycloakService.loadUserProfile();
    }

    return;
  }

  logout(): void {
    this.keycloakService.logout().then(noop);
  }
}
